var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { useToastActions } from 'common/services/toast-service/hooks';
import { toastServiceDataSelector, toastServiceLastToastIdSelector } from 'common/services/toast-service/selectors';
import { Portal } from 'ui-kit/portal';
import { Icon } from 'ui-kit/icon';
import { InfoCircleIcon, SuccessCircleIcon } from './icons';
import { useBreakpoints } from 'common/hooks/use-breakpoints';
import { ONE_SECOND } from 'common/constants/common-time';
import styles from './toast.module.scss';
var DURATION = ONE_SECOND * 5;
var positionMap = (_a = {},
    _a['top-center'] = styles.topCenter,
    _a['bottom-right'] = styles.bottomRight,
    _a);
var toastTypeMap = (_b = {},
    _b['success'] = styles.success,
    _b['error'] = styles.error,
    _b['info'] = styles.info,
    _b);
export var Toast = function () {
    var removeToast = useToastActions().removeToast;
    var toasts = useSelector(toastServiceDataSelector);
    var lastToastId = useSelector(toastServiceLastToastIdSelector);
    var _a = useState({}), setTimeoutId = _a[1];
    var isDesktop = useBreakpoints('desktop').isDesktop;
    var position = isDesktop ? 'bottom-right' : 'top-center';
    useEffect(function () { return function () {
        setTimeoutId(function (prev) {
            for (var key in prev) {
                if ({}.hasOwnProperty.call(prev, key)) {
                    clearInterval(prev[key]);
                }
            }
            return {};
        });
    }; }, [setTimeoutId]);
    var removeTimeoutId = useCallback(function (key) {
        setTimeoutId(function (prev) {
            clearTimeout(prev[key]);
            delete prev[key];
            return __assign({}, prev);
        });
    }, []);
    var createTimeout = useCallback(function (key) {
        var timeoutId = Number(setTimeout(function () {
            removeToast(key);
            removeTimeoutId(key);
        }, DURATION));
        setTimeoutId(function (prev) {
            var _a;
            return __assign(__assign({}, prev), (_a = {}, _a[key] = timeoutId, _a));
        });
    }, [setTimeoutId, removeTimeoutId, removeToast]);
    useEffect(function () {
        if (lastToastId)
            createTimeout(lastToastId);
    }, [lastToastId, createTimeout]);
    var handleOnInter = useCallback(function (key) { return function () {
        removeTimeoutId(key);
    }; }, [removeTimeoutId]);
    var handleOnLeave = useCallback(function (key) { return function () {
        createTimeout(key);
    }; }, [createTimeout]);
    var getToastList = useCallback(function () {
        var toastList = toasts.map(function (item) { return (_jsxs("div", __assign({ className: cs(styles.notification, positionMap[position], toastTypeMap[item.type || 'info']), "data-testid": "toastContainer", style: { animationDuration: "".concat(DURATION, "ms") }, onMouseEnter: handleOnInter(item.id), onMouseLeave: handleOnLeave(item.id) }, { children: [_jsx("div", __assign({ className: styles.iconWrapper }, { children: _jsx(Icon, { width: 1.5, className: styles.icon, component: item.type === 'success' ? SuccessCircleIcon : InfoCircleIcon }) })), _jsxs("div", __assign({ className: styles.content }, { children: [item.title && (_jsx("p", __assign({ className: styles.title, "data-testid": "toastTitle" }, { children: item.title }))), item.message && (_jsx("p", __assign({ className: styles.message, "data-testid": "toastMessage" }, { children: item.message })))] }))] }), item.id)); });
        if (position === 'top-center')
            toastList.reverse();
        return toastList;
    }, [handleOnInter, handleOnLeave, toasts, position]);
    return (_jsx(Portal, { children: toasts.length ? _jsx("div", __assign({ className: cs(styles.root, positionMap[position]) }, { children: getToastList() })) : null }));
};
