var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { Typography } from 'ui-kit/typography';
import { ControllerWrapper } from '../controller-wrapper';
import { Icon } from 'ui-kit/icon';
import { AttachSquareIcon } from './icons';
import { FilesList } from './modules';
import { useDropzoneHandler } from './use-dropzone-handler';
import { getFileFromDataUrl } from './utils';
import { TEN_MB } from 'common/constants/file-sizes';
import styles from './dropzone.module.scss';
export var Dropzone = function (_a) {
    var _b;
    var name = _a.name, title = _a.title, value = _a.value, accept = _a.accept, _c = _a.maxFiles, maxFiles = _c === void 0 ? 1 : _c, _d = _a.maxSize, maxSize = _d === void 0 ? TEN_MB : _d, isError = _a.isError, disabled = _a.disabled, className = _a.className, style = _a.style, onChange = _a.onChange, onBlur = _a.onBlur, rest = __rest(_a, ["name", "title", "value", "accept", "maxFiles", "maxSize", "isError", "disabled", "className", "style", "onChange", "onBlur"]);
    var _e = useDropzoneHandler({ value: value, maxFiles: maxFiles, maxSize: maxSize, onChange: onChange }), rejectedFiles = _e.rejectedFiles, onDrop = _e.onDrop, removeItem = _e.removeItem;
    var _f = useDropzone({
        accept: accept,
        multiple: maxFiles > 1,
        maxFiles: maxFiles,
        onDrop: onDrop,
    }), getRootProps = _f.getRootProps, getInputProps = _f.getInputProps, isDragActive = _f.isDragActive;
    var files = useMemo(function () { return __spreadArray(__spreadArray([], value, true), rejectedFiles, true); }, [value, rejectedFiles]);
    var isHoveredRef = useRef(false);
    var _g = getRootProps(), ref = _g.ref, rootProps = __rest(_g, ["ref"]);
    var inputRef = ref;
    var t = useTranslation().t;
    var handleHoverOn = useCallback(function () {
        isHoveredRef.current = true;
    }, []);
    var handleHoverOff = useCallback(function () {
        isHoveredRef.current = false;
    }, []);
    var checkInputImages = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var pasteCatcher, child, src, file, rejectFile, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pasteCatcher = ref.current;
                    child = pasteCatcher === null || pasteCatcher === void 0 ? void 0 : pasteCatcher.children[0];
                    src = (child === null || child === void 0 ? void 0 : child.tagName) === 'IMG' ? child.src : '';
                    if (!src) return [3 /*break*/, 4];
                    child.src = '';
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getFileFromDataUrl(src, "".concat(Date.now(), "_image"))];
                case 2:
                    file = _a.sent();
                    if (file) {
                        if (!accept || (accept.includes(file.type) && file.size && file.size < maxSize)) {
                            onDrop([file], []);
                        }
                        else {
                            rejectFile = {
                                file: file,
                                errors: [
                                    {
                                        code: !file.size ? 'upload-error' : file.size > maxSize ? 'file-too-large' : 'file-invalid-type',
                                        message: '',
                                    },
                                ],
                            };
                            onDrop([], [rejectFile]);
                        }
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 4:
                    if (pasteCatcher) {
                        pasteCatcher.innerHTML = '';
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [onDrop, ref, accept, maxSize]);
    useEffect(function () {
        var cb = function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var event, acceptFiles_1, rejectFiles_1;
            var _a;
            return __generator(this, function (_b) {
                event = e;
                if (isHoveredRef.current && ((_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.items.length)) {
                    event.preventDefault();
                    acceptFiles_1 = [];
                    rejectFiles_1 = [];
                    Array.prototype.forEach.call(event.clipboardData.items, function (item) {
                        var file = item.getAsFile();
                        if (file) {
                            var fileType = file.type.split('/').at(-1);
                            var fileName = file.name === "image.".concat(fileType) ? "".concat(Date.now(), "_image.").concat(fileType) : file.name;
                            var newFile = new File([file], fileName, { type: file.type });
                            if (!accept || (accept.includes(file.type) && file.size < maxSize)) {
                                acceptFiles_1.push(newFile);
                            }
                            else {
                                rejectFiles_1.push({
                                    file: newFile,
                                    errors: [{ code: file.size > maxSize ? 'file-too-large' : 'file-invalid-type', message: '' }],
                                });
                            }
                        }
                    });
                    if (acceptFiles_1.length || rejectFiles_1.length)
                        onDrop(acceptFiles_1, rejectFiles_1);
                }
                else {
                    setTimeout(checkInputImages, 300);
                }
                return [2 /*return*/];
            });
        }); };
        window.addEventListener('paste', cb);
        return function () {
            window.removeEventListener('paste', cb);
        };
    }, [onDrop, checkInputImages, accept, maxSize]);
    return (_jsxs(ControllerWrapper, __assign({ name: name, isError: isError }, rest, { children: [_jsxs("div", __assign({ className: cs(styles.dropzone, (_b = {}, _b[styles.error] = isError, _b), className), style: style }, { children: [_jsx(Icon, { className: styles.icon, component: AttachSquareIcon }), _jsx(Typography, __assign({ variant: "body-s" }, { children: title })), isDragActive ? (_jsx(Typography, __assign({ className: styles.dragMessage, variant: "body-s" }, { children: t('uiKit.dropzone.moveAreaText') }))) : null, _jsx("div", __assign({ ref: inputRef, className: styles.activator, contentEditable: true, "data-testid": "".concat(name, "-openDropsoneButton"), onMouseOver: !disabled ? handleHoverOn : undefined, onMouseOut: !disabled ? handleHoverOff : undefined }, rootProps))] })), _jsx("input", __assign({ name: name, disabled: disabled, "data-testid": name, onBlur: onBlur }, getInputProps())), _jsx(FilesList, { files: files, removeItem: removeItem })] })));
};
