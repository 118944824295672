var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import cs from 'classnames';
import styles from './icon.module.scss';
var colorMap = (_a = {
        primary: styles.primary
    },
    _a['primary-light'] = styles.primaryLight,
    _a.text = styles.text,
    _a['text-light'] = styles.textLight,
    _a.contrast = styles.contrast,
    _a.success = styles.success,
    _a.error = styles.error,
    _a.warning = styles.warning,
    _a.none = '',
    _a);
export var Icon = function (_a) {
    var _b = _a.width, width = _b === void 0 ? 1.5 : _b, h = _a.height, component = _a.component, color = _a.color, _c = _a.viewBox, viewBox = _c === void 0 ? '0 0 24 24' : _c, customWidth = _a.customWidth, customHeight = _a.customHeight, dataTestid = _a.dataTestid, className = _a.className, rest = __rest(_a, ["width", "height", "component", "color", "viewBox", "customWidth", "customHeight", "dataTestid", "className"]);
    var BaseIcon = component;
    var height = h || width;
    return (_jsx(BaseIcon, __assign({ className: cs(styles.baseIcon, color ? colorMap[color] : rest.fill ? undefined : styles.primary, className), width: customWidth ? customWidth : width && "".concat(width, "rem"), height: customHeight ? customHeight : height && "".concat(height, "rem"), viewBox: viewBox, "data-testid": dataTestid }, rest)));
};
